import React, { useEffect, useState, useCallback } from 'react';
import GroupButtons from '../../components/GroupButtons';
import { api } from '../../services/api';

const Meetings = () => {
    const [meetings, setMeetings] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    // Função para buscar o ID do professor e suas reuniões
    const fetchMeetings = async () => {
        try {
            const userResponse = await api.get('/user-info'); // Pegue informações do professor
            const fetchedTeacherId = userResponse.data.user._id;
            
            // Fetch meetings por teacherId
            const response = await api.get(`/meetings/teacher/${fetchedTeacherId}`);
            setMeetings(response.data); // Substitui o estado atual
        } catch (err) {
            const errorMessage = err.response?.data?.message || 'Erro ao buscar reuniões.';
            setErrorMessage(errorMessage);
            setTimeout(() => setErrorMessage(''), 5000);
        }
    };

    const fetchStudentInfo = useCallback(async (studentEmail, setName, setEmail) => {
        try {
            const response = await api.get('/get-user-info', {
                params: { email: studentEmail } 
            });

            const student = response.data.userInfo;
            const studentName = student.firstName + " " + student.lastName;

            setName(studentName);
            setEmail(response.data.user.email);
        } catch (err) {
            console.error(`Erro ao buscar nome do estudante com email ${studentEmail}:`, err);
        }
    }, []);

    // Função para alterar o campo occurred da reunião
    const handleUpdateOccurred = async (meetingId, occurredStatus) => {
        try {
            await api.put(`/meetings/${meetingId}/occurred`, { occurred: occurredStatus });
            setMeetings(prevMeetings => prevMeetings.map(meeting =>
                meeting._id === meetingId ? { ...meeting, occurred: occurredStatus } : meeting
            ));
        } catch (err) {
            setErrorMessage('Erro ao atualizar o status da reunião.');
            setTimeout(() => setErrorMessage(''), 5000);
        }
    };

    useEffect(() => {
        fetchMeetings();
    }, []); 

    return (
        <div className='py-4'>
            <GroupButtons professor={true} />
            <div className="min-w-80 mr-6 ml-4 bg-white py-4 rounded-md flex flex-col items-center">
                <h3 className="px-4 font-bold mb-10 text-orange-500 text-2xl">Reuniões Agendadas</h3>
                <ul className="px-4 w-full">
                    {meetings.length > 0 ? (
                        meetings.map((meeting) => (
                            <MeetingItem
                                key={meeting._id}
                                meeting={meeting}
                                handleUpdateOccurred={handleUpdateOccurred}
                                fetchStudentInfo={fetchStudentInfo}
                            />
                        ))
                    ) : (
                        <p className="text-gray-500">Nenhuma reunião agendada no momento.</p>
                    )}
                </ul>
                {errorMessage && (
                    <div className="fixed bottom-4 z-50 right-4 bg-red-500 text-white px-4 py-2 rounded">
                        {errorMessage}
                    </div>
                )}
            </div>
        </div>
    );
};

// Componente MeetingItem para renderizar cada reunião
const MeetingItem = ({ meeting, handleUpdateOccurred, fetchStudentInfo }) => {
    const [studentName, setStudentName] = useState('');
    const [studentEmail, setStudentEmail] = useState('');

    useEffect(() => {
        fetchStudentInfo(meeting.studentEmail, setStudentName, setStudentEmail); // Usando o email do estudante
    }, [meeting.studentEmail, fetchStudentInfo]);

    return (
        <li className="mb-2 p-2 border border-gray-300 rounded-md flex justify-between items-center">
            {/* Informações de Estudante, Data e Horário */}
            <div className="flex flex-col">
                <p><strong>Estudante:</strong> {studentName || 'Carregando...'}</p>
                <p><strong>Email:</strong> {studentEmail || 'Carregando...'}</p>
                <p><strong>Data:</strong> {new Date(meeting.date).toLocaleDateString('pt-BR')}</p>
                <p><strong>Horário:</strong> {new Date(meeting.date).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}</p>
            </div>

            <div className="flex flex-col items-end">
                <p>
                    <strong className={meeting.occurred === true ? 'text-green-500 text-lg' : meeting.occurred === false ? 'text-red-500 text-lg' : 'text-yellow-500 text-lg'}>
                        {meeting.occurred === null ? 'Pendente' : meeting.occurred ? 'Ocorrida' : 'Não Ocorrida'}
                    </strong>
                </p>

                {/* Botões para marcar como ocorrida ou não ocorrida */}
                <div className="mt-2 flex space-x-2">
                    <button
                        onClick={() => handleUpdateOccurred(meeting._id, true)}
                        className="px-4 py-2 bg-green-500 text-white rounded-md"
                    >
                        Ocorrida
                    </button>
                    <button
                        onClick={() => handleUpdateOccurred(meeting._id, false)}
                        className="px-4 py-2 bg-red-500 text-white rounded-md"
                    >
                        Não Ocorrida
                    </button>
                </div>
            </div>
        </li>
    );
};

export default Meetings;
