import React, { useEffect, useState } from 'react';
import GroupButtons from '../../components/GroupButtons';
import { api } from '../../services/api';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

const Horarios = () => {
  const [freeSlots, setFreeSlots] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [sucessMessage, setSucessMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [teacherId, setTeacherId] = useState(null);
  const [teacherName, setTeacherName] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false); 
  const [selectedEventInfo, setSelectedEventInfo] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [classRequests, setClassRequests] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [events, setEvents] = useState([]);
  const [loadingSolicitacao, setloadingSolicitacao] = useState(false);

  // Estado para armazenar o evento selecionado
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Função para buscar horários livres (GET)
  const fetchFreeSlots = async () => {
    try {
      const response = await api.get('/free-slots', {
        params: { teacherId },
      });
      setFreeSlots(response.data.freeSlots);
      setErrorMessage('');
    } catch (err) {
      console.error(err);
      const errorMessage = err.response?.data?.message || 'Erro ao buscar horários livres.';
      setErrorMessage(errorMessage);
      setTimeout(() => setErrorMessage(''), 5000);
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar o ID do professor e suas reuniões
  const fetchMeetings = async () => {
    try {
      // Fetch meetings por teacherId
      const response = await api.get(`/meetings/teacher/${teacherId}`);
      setMeetings(response.data);
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Erro ao buscar reuniões.';
      setErrorMessage(errorMessage);
      setTimeout(() => setErrorMessage(''), 5000);
      console.log(err)
    }
  };

  const fetchClassRequests = async () => {
    try {
      const response = await api.get('/reservation-request/teacher', {
        params: { teacherId },
      });
      setClassRequests(response.data.reservation);

    } catch (err) {
      console.error(err);
      const errorMessage = err.response?.data?.message || 'Erro ao buscar requisições.';
      setErrorMessage(errorMessage);
      setTimeout(() => setErrorMessage(''), 5000);
    }
  };

  // Função para criar horários livres (POST)
  const createFreeSlot = async (date) => {
    try {
      await api.post('/free-slots', {
        teacherId,
        date,
      });
      fetchFreeSlots(teacherId);
    } catch (err) {
      console.error(err);
      const errorMessage = err.response?.data?.message || 'Erro ao criar horário livres.';
      setErrorMessage(errorMessage);
      setTimeout(() => setErrorMessage(''), 5000);
    }
  };

  const deleteFreeSlot = async (slotId) => {
    try {
      await api.delete(`/free-slots/${slotId}`);
      fetchFreeSlots(teacherId);
      setIsEventDetailsOpen(false);
    } catch (err) {
      console.error(err);
      const errorMessage = err.response?.data?.message || 'Erro ao deletar horário livres.';
      setErrorMessage(errorMessage);
      setTimeout(() => setErrorMessage(''), 5000);
    }
  };

  // useEffect para buscar o `teacherId` do usuário
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userResponse = await api.get('/user-info', {});
        const userId = userResponse.data.user._id;
        setTeacherId(userId);
        setTeacherName(userResponse.data.user.firstName + " " + userResponse.data.user.lastName);
      } catch (err) {
        console.error(err);
        setErrorMessage('Erro ao obter informações do usuário.');
        setTimeout(() => setErrorMessage(''), 5000);
      }
    };

    fetchUserInfo();
  }, []);

  // useEffect para buscar os horários livres assim que o `teacherId` estiver disponível
  useEffect(() => {
    if (teacherId) {
      fetchFreeSlots();
      fetchClassRequests();
      fetchMeetings();
    }
  }, [teacherId]);

  useEffect(() => {
    const freeSlotEvents = freeSlots.map(slot => ({
      id: slot._id,
      title: 'Livre',
      date: slot.date,
      extendedProps: {
        teacherId: slot.teacherId,     // Adicione `teacherId` dentro de `extendedProps`
        isAvailable: slot.isAvailable, // Adicione `isAvailable` dentro de `extendedProps`
      },
    }));

    const meetingEvents = meetings.map(meeting => ({
      id: meeting._id,
      title: 'Aula',
      date: meeting.date,
      color: '#FA7F08',
      extendedProps: {
        teacherId: meeting.teacherId,     // Adicione `teacherId` dentro de `extendedProps`
        isAvailable: meeting.ocurred, // Adicione `isAvailable` dentro de `extendedProps`
      },
    }));

    setEvents([...freeSlotEvents, ...meetingEvents]);
    console.log(events)
  }, [freeSlots, meetings]);

  const formatTimeLocal = (date) => {
    return date.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'America/Sao_Paulo', // Especificar o fuso horário correto
    });
  };

  // Função para lidar com o clique em um evento do calendário
  const handleEventClick = (info) => {
    setSelectedEvent(info); // Define o evento selecionado
    setIsEventDetailsOpen(true);  // Abre o modal de detalhes
  };

  // Função para lidar com o clique em um espaço vazio do calendário
  const handleDateClick = (info) => {
    const start = new Date(info.date);
    const end = new Date(start);
    end.setHours(end.getHours() + 1); // Definindo o horário final padrão para uma hora após o inicial

    setSelectedEventInfo({
      teacherName: teacherName,
      date: start,
    });

    setStartTime(formatTimeLocal(start)); // Formatar o horário inicial corretamente
    setEndTime(formatTimeLocal(end));     // Formatar o horário final corretamente
    setIsModalOpen(true);
  };

  // Função para confirmar a criação de novos horários
  const handleConfirm = () => {
    const start = new Date(selectedEventInfo.date);
    const end = new Date(selectedEventInfo.date);
    start.setHours(parseInt(startTime.split(':')[0]), parseInt(startTime.split(':')[1]));
    end.setHours(parseInt(endTime.split(':')[0]), parseInt(endTime.split(':')[1]));

    const slots = [];
    let current = new Date(start);

    // Gera slots de uma hora cada
    while (current < end) {
      const next = new Date(current);
      next.setHours(current.getHours() + 1);
      slots.push(current.toISOString());
      current = next;
    }

    slots.forEach(date => {
      createFreeSlot(date);
    })
    // Envia todos os horários gerados
    setIsModalOpen(false);
  };

  // Função para fechar o modal
  const handleClose = () => {
    setIsModalOpen(false);
  };

  // Função para aceitar ou recusar um pedido de aula
  const handleRequestAction = async (requestId, status) => {
    setloadingSolicitacao(true)
    try {
      // Enviando o status no corpo da requisição
      await api.post(`/reservation-request/${requestId}/respond`, {
        status,  // "ACCEPTED" ou "REJECTED"
      });
      // Atualiza a lista de pedidos após a ação
      fetchClassRequests();
      fetchFreeSlots();
      fetchMeetings();

      if (status === 'ACCEPTED') {
        setSucessMessage("Solicitação aceita!")
      } else if (status === 'REJECTED') {
        setSucessMessage("Solicitação recusada!")
      }
      setTimeout(() => setSucessMessage(''), 5000);

    } catch (err) {
      console.error(err);
      setErrorMessage('Erro ao atualizar o status da solicitação.');
      setTimeout(() => setErrorMessage(''), 5000);
    } finally {
      setloadingSolicitacao(false)
    }
  };


  // Componente do modal de confirmação
  const ConfirmDialog = ({ isOpen, onClose, onConfirm, eventInfo }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">Criar horário livre</h3>
          <p><strong>Professor:</strong> {eventInfo.teacherName}</p>
          <p><strong>Data:</strong> {eventInfo.date.toLocaleDateString("pt-BR")}</p>

          {/* Campos para selecionar os horários */}
          <div className="mb-4">
            <label className="block">Horário Inicial:</label>
            <input
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className="border p-2 rounded-md w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block">Horário Final:</label>
            <input
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className="border p-2 rounded-md w-full"
            />
          </div>

          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md"
            >
              Cancelar
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const EventDetailsDialog = ({ isOpen, event, onDelete, onClose }) => {
    if (!isOpen || !event) return null;

    // Certifique-se de que start e end sejam objetos de Date
    const start = new Date(event.event.start); // evento do FullCalendar geralmente tem 'start'
    const end = new Date(event.event.end || start); // se 'end' não existir, use o mesmo horário do 'start'
    console.log(event.event.teacherId)
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">Detalhes do Horário</h3>
          <p><strong>Professor: </strong> {teacherName}</p>
          <p><strong>Data:</strong> {start.toLocaleDateString("pt-BR")}</p>
          <p><strong>Horário:</strong> {formatTimeLocal(start)} - {formatTimeLocal(end)}</p>
          {event.event.extendedProps.isAvailable === true ? <p>disponível</p> : <p>Horário reservado: </p>}

          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md"
            >
              Fechar
            </button>
            <button
              onClick={() => onDelete(event.event.id)}
              className="px-4 py-2 bg-red-500 text-white rounded-md"
            >
              Excluir
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="py-4">
      <GroupButtons professor={true} />

      <div className="scd-overlay">
        <div className='scd-container'>
          {loading ? (
            <p>Carregando...</p>
          ) : (
            <>
              {errorMessage && (
                <div className="fixed bottom-4 z-50 right-4 bg-red-500 text-white px-4 py-2 rounded">
                  {errorMessage}
                </div>
              )}

              {sucessMessage && (
                <div className="fixed bottom-4 z-50 right-4 bg-green-500 text-white px-4 py-2 rounded">
                  {sucessMessage}
                </div>
              )}

              {loadingSolicitacao && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <p><strong>Enviando solicitação...</strong></p>
                  </div>
                </div>
              )}

              <div className="flex w-full h-full bg-gray-100 radius-5 rounded-md p-6 shadow-[2px_3px_5px_0px_rgba(0,0,0,0.15)]">
                <div className="min-w-80 mr-6 bg-white py-4 rounded-md flex flex-col items-center shadow-[2px_3px_5px_0px_rgba(0,0,0,0.15)]">
                  <h3 className=" px-4 font-bold mb-10 text-orange-500 text-2xl">Pedidos de aula</h3>
                  <ul className="px-4 w-full overflow-y-auto">
                    {classRequests.length > 0 ? (
                      classRequests
                        .filter((request) => request.status === 'PENDING')
                        .map((request) => (
                          <li
                            key={request._id}
                            className="mb-2 p-2 border-b border-gray-300"
                          >
                            <p><strong>Aluno:</strong> {request.studentName}</p>
                            <p><strong>Data:</strong> {new Date(request.slotDate).toLocaleDateString('pt-BR')}</p>
                            <p><strong>Horário:</strong> {new Date(request.slotDate).toLocaleTimeString('pt-BR')}</p>
                            <div className="flex space-x-2 mt-2">
                              <button
                                onClick={() => handleRequestAction(request._id, 'ACCEPTED')}
                                className="px-4 py-2 bg-green-500 text-white rounded-md"
                              >
                                Aceitar
                              </button>
                              <button
                                onClick={() => handleRequestAction(request._id, 'REJECTED')}
                                className="px-4 py-2 bg-red-500 text-white rounded-md"
                              >
                                Recusar
                              </button>
                            </div>
                          </li>
                        ))
                    ) : (
                      <p className="text-gray-500">Nenhum pedido de aula no momento.</p>
                    )}
                  </ul>
                </div>
                <div className="flex-grow bg-white p-4 shadow-[2px_3px_5px_0px_rgba(0,0,0,0.15)]">
                  {teacherId && (
                    <>
                      <h3 className="font-bold text-center text-2xl mb-4">
                        Calendário com Horários livres
                      </h3>
                      <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                        initialView="timeGridWeek"
                        locale="pt-br"
                        events={events}
                        headerToolbar={{
                          start: 'today prev next',
                          end: 'dayGridMonth timeGridWeek dayGridDay',
                        }}
                        eventClick={handleEventClick}
                        dateClick={handleDateClick} // Função para lidar com o clique em espaços vazios
                        slotLabelFormat={{
                          hour: '2-digit',
                          minute: '2-digit',
                          meridiem: false,
                          hour12: false,
                        }}
                        expandRows={true}
                        style={{
                          height: '100%',
                          width: '100%',
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

              <ConfirmDialog
                isOpen={isModalOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                eventInfo={selectedEventInfo}
              />

              <EventDetailsDialog
                isOpen={isEventDetailsOpen}
                event={selectedEvent}
                onDelete={deleteFreeSlot}
                onClose={() => setIsEventDetailsOpen(false)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Horarios;
