import React from 'react'
import PhotoLobo from '../../assets/lobopic.jpg'
import PhotoGilberti from '../../assets/gilbertipic.jpeg'
const teachers = [
  {
    id: "664577c24cb74f6c127e5ab9",
    name: "Arthur Lobo",
    photo: PhotoLobo,
    topic: "informática"
  },
  {
    id: "66926a660a0a09c52572a50e",
    name: "Caique Paiva",
    photo: "",
    topic: "informática"
  },
  {
    id: "672a82a90a0a09c525749288",
    name: "Leandro Checcio",
    photo: "",
    topic: "informática"
  },
  {
    id: "6682f1b580e7b31672c4af7e",
    name: "João Pedro Castro",
    photo: "",
    topic: "informática"
  },
  {
    id: "672c46700a0a09c525749475",
    name: "João Gilberti",
    photo: PhotoGilberti,
    topic: "matemática"
  },
  // {
  //   id: "66ff36720a0a09c52574150d",
  //   name: "Fernando teste",
  //   photo: PhotoGilberti,
  //   topic: "informática"
  // },
]

const UlProfessr = ({ topico, onTeacherClick, selectedTeacher }) => {
  const filteredTeachers = teachers.filter(teacher => teacher.topic === topico);

  return (
    <ul className='w-full overflow-y-auto'>
      {filteredTeachers.map((teacher, index) => (
        <li
          key={index}
          className={`py-3 px-3 flex items-center w-full border-b border-gray-200 justify-start cursor-pointer ${selectedTeacher === teacher ? 'bg-blue-500' : ''}`}
          onClick={() => onTeacherClick(teacher)}
        >
          <span className="mr-2">
            <figure className='w-16'>
            {teacher.photo ? (
                <img
                  src={teacher.photo}
                  className="w-full rounded-full shadow-2xl"
                  alt={`${teacher.name}`}
                />
              ) : (
                <div className="w-16 h-16 flex items-center justify-center rounded-full bg-orange-500 text-white text-xl font-bold shadow-2xl">
                  {teacher.name.charAt(0).toUpperCase()}
                </div>
              )}
            </figure>
          </span>
          <span className={`font-bold ml-5 text-2xl ${selectedTeacher === teacher ? 'text-white' : 'text-blue-400 '}`}>{teacher.name}</span>
        </li>
      ))}
    </ul>
  );
};



export default UlProfessr